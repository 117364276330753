import(/* webpackMode: "eager" */ "/srv/gitlab/builds/k8s-managed-publishing/WVBC6qub/0/publishing/rtlde/frontend/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/srv/gitlab/builds/k8s-managed-publishing/WVBC6qub/0/publishing/rtlde/frontend/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/srv/gitlab/builds/k8s-managed-publishing/WVBC6qub/0/publishing/rtlde/frontend/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/srv/gitlab/builds/k8s-managed-publishing/WVBC6qub/0/publishing/rtlde/frontend/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/srv/gitlab/builds/k8s-managed-publishing/WVBC6qub/0/publishing/rtlde/frontend/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/srv/gitlab/builds/k8s-managed-publishing/WVBC6qub/0/publishing/rtlde/frontend/node_modules/next/dist/client/components/render-from-template-context.js");
